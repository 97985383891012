import WOW from "wowjs";

import "../home/components/navigation/navigation";
import "../home/index.css";
import "./index.css";
import "./components/scene-1/scene-1";
import "./components/scene-2/scene-2";
import "./components/scene-3/scene-3";
import "./components/scene-4/scene-4";

import "../analytics";

var wow = new WOW.WOW({
  boxClass: "wow", // default
  animateClass: "animated", // default
  offset: 0, // default
  mobile: true,
  live: false,
});
wow.init();
